import React, { FC } from "react"
import styled from "styled-components"

interface KardraItemProps {
  img: string
  name: string
  desc: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`

const Avatar = styled.div<{ $src: string }>`
  width: 70px;
  height: 70px;
  background-image: url(${({ $src }) => $src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 0 15px -2px #000;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 120px;
    height: 120px;
  }
`

const Wrapper = styled.div`
  margin-left: 20px;
`

const Name = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 24px;
  }
`

const Desc = styled.p`
  color: #a0a0a0;
  font-weight: bold;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 20px;
  }
`

export const KardraItem: FC<KardraItemProps> = ({ desc, img, name }) => {
  return (
    <Container>
      <Avatar $src={img} />
      <Wrapper>
        <Name>{name}</Name>
        <Desc>{desc}</Desc>
      </Wrapper>
    </Container>
  )
}

export default KardraItem
