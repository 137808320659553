import React, { FC } from "react"
import styled from "styled-components"
import { PageTitle } from "../../components/PageTitle"
import { Layout } from "../../layout/Layout"
import { KardraItem } from "./Components/KadraItem"

import Ania from "../../../static/images/Ania.jpg"
import Darek from "../../../static/images/Darek.png"
import Pawel from "../../../static/images/Pawel.png"
import Mariusz from "../../../static/images/Mariusz.jpg"
import Pojazd from "../../../static/images/pojazd.jpg"
import HelmetData from "../../components/HelmetData"

interface KadraProps {}

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

export const Kadra: FC<KadraProps> = () => {
  return (
    <Layout>
      <HelmetData page="Kadra" canon="/kadra" />
      <PageTitle title="NASZA KADRA" />
      <Content>
        <KardraItem
          img={Ania}
          name="Ania"
          desc="Kierownik ośrodka, instruktor"
        />
        <KardraItem img={Mariusz} name="Mariusz" desc="Instruktor" />
        <KardraItem img={Darek} name="Darek" desc="Instruktor" />
        <KardraItem img={Pawel} name="Pawel" desc="Ratownik medyczny" />
      </Content>
      <PageTitle title="Pojazd" contrast />
      <Content>
        <KardraItem img={Pojazd} name="Kia Rio" desc="Taka jak na egzaminie" />
      </Content>
      <PageTitle title="Zapraszamy" marginBottom={30} />
    </Layout>
  )
}

export default Kadra
